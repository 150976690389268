<template>
    <div class="page">
        <div class="top">
            <div class="teacherBox">
                <div class="name">
                    <img :src="require('@/assets/home/tx_ls.png')" alt="">
                    <span>当前教师：{{ mark.teacherStates[markIndex].TeacherName }}</span>
                </div>
                <div :class="data.IsComplete ? 'yes' : 'not'">{{ data.IsComplete ? '已评分' : '未评分' }}</div>
            </div>
            <div class="title">{{ data.Assessment_Name }}</div>
            <div class="tips">
                <div class="tipstext">{{ data.Assessment_Description }}  </div>
                <div @click="mfScore" v-if="$moment(data.Answer_End_Time).valueOf() < $moment().valueOf() ? false : true" class="tipsbtn">
                    <span class="tipsbtntext">一键满分</span></div>
            </div>
            <div class="info">
                <span>考核时间：{{ $moment(data.Answer_Start_Time).format('YYYY-MM-DD') }}~{{
                        $moment(data.Answer_End_Time).format('YYYY-MM-DD') }}</span>
                <span>发起人：{{ data.Publish_User_Name }}</span>
            </div>
        </div>
        <div class="center">
            <template v-if="data.AssessmentType == 1 && data.IsSelfComplate == 0">
                <div style="text-align: center;margin-top: 20px;">
                    <img :src="require('@/assets/home/wj_pic_tjcg.png')" alt="">
                    <div> 该教师尚未完成自评</div>
                </div>
            </template>
            <template v-else>
                <div class="item" v-for="(item, index) in data.TemplateParents" :key="index">
                    <div class="bigTit">{{ item.Parent_Title }}({{ item.Parent_Score_Max }}分)</div>
                    <div class="bigTopic" v-if="item.Is_SetScore == 1">
                        <van-field v-model="item.Self_Assessment_Score"
                            v-if="data.AssessmentType == 1 || data.AssessmentType == 2" type="number" label="自评分:"
                            placeholder="请输入" readonly />

                        <van-field v-model="item.ManUser_Score"
                            v-if="(data.AssessmentType == 1 && data.IsSelfComplate == 1) || data.AssessmentType == 3"
                            type="number" label="考核评分：" placeholder="请输入"
                            :disabled="$moment(data.Answer_End_Time).valueOf() < $moment().valueOf() ? true : false"
                            @input="changeScore(arguments[0], item.Parent_Score_Max, index)"
                            :class="item.Parent_Score_Max==data.TemplateParents[index].ManUser_Score ? '' : 'fontred'" />
                    </div>

                    <div class="childBox" v-if="item.Children.length">
                        <div class="child" v-for="(val, ind) in item.Children" :key="ind">
                            <div class="smallTit">{{ val.Child_Title }}(标准分{{ val.Child_Score_Max }}分)</div>
                            <div class="smallTopic">
                                <van-field v-model="val.Self_Assessment_Score"
                                    v-if="data.AssessmentType == 1 || data.AssessmentType == 2" type="number"
                                    label="自评分:" placeholder="请输入" readonly />

                                <van-field v-model="val.ManUser_Score"
                                    v-if="(data.AssessmentType == 1 && data.IsSelfComplate == 1) || data.AssessmentType == 3"
                                    type="number" label="考核评分：" placeholder="请输入"
                                    :disabled="$moment(data.Answer_End_Time).valueOf() < $moment().valueOf() ? true : false"
                                    @input="changeChild(arguments[0], val.Child_Score_Max, index, ind)"
                                    :class="val.Child_Score_Max==data.TemplateParents[index].Children[ind].ManUser_Score ? '' : 'fontred'" />
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="item" v-if="data.AssessmentType == 1 || data.AssessmentType == 2">
                    <div class="bigTit">考核备注</div>
                    <div class="bigTopic"> 
                        <van-field type="textarea" :disabled="$moment(data.Answer_End_Time).valueOf() < $moment().valueOf() ? true : false" v-model="data.Man_Remark" />
                    </div>
                </div>
            
                <div class="item">
                    <div class="bigTit">总分</div>
                    <div class="scoreBig">
                        <div class="" v-if="data.AssessmentType == 1 || data.AssessmentType == 2">
                            <span>自评总分：</span>
                            <label>{{allSelfDivide}}</label>
                        </div>
                        <div class="" v-if="data.AssessmentType == 1 || data.AssessmentType == 3">
                            <span>考核评总分：</span>
                            <label>{{allDivide}}</label>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <div class="btn">
            <div class="next" @click="nextUp">上一位</div>
            <van-button type="info"
                :disabled="$moment(data.Answer_End_Time).valueOf() < $moment().valueOf() ? true : false"
                @click="submit">保存</van-button>
            <div class="next" @click="nextDown">下一位</div>
        </div>
    </div>
</template>

<script>
import { Toast } from "vant";
export default {
    data() {
        return {
            // user: this.whale.user.get(),
            data: {},
            teacherId: 0,
            mark: {},
            markIndex: 0,
            allDivide: 0,
            allSelfDivide: 0,
        }
    },
    created() {
        this.mark = JSON.parse(sessionStorage.getItem('markData'))
        if (this.$route.query.teacherId) {
            this.teacherId = this.$route.query.teacherId
            this.mark.teacherStates.map((i, j) => {
                if (i.TeacherId == this.teacherId) {
                    this.markIndex = j
                }
            })
            console.log(this.markIndex)
            this.getInfo()
        } else {
            this.teacherId = this.mark.teacherStates[0].TeacherId
            this.markIndex = 0
            this.getInfo()
        }
    },
    methods: {
        getInfo() {
            let self = this;
            this.whale.remote.getResult({
                url: "/api/Mobile/PFM/PfmRecord/GetAssessmentDetails?guid=" + self.$route.query.guid + '&teacherId=' + self.teacherId,
                completed(m) {
                    console.log(m)
                    self.data = m.DATA
                    self.refashMaxScore()
                },
            });
        },
        nextUp() {
            if (this.markIndex == 0) {
                Toast.fail('已经到第一位')
                return
            } else if (this.markIndex > 0) {
                this.markIndex--
            } else {
                this.markIndex = 0
            }
            this.teacherId = this.mark.teacherStates[this.markIndex].TeacherId
            this.getInfo()
        },
        nextDown() {
            if (this.markIndex == this.mark.teacherStates.length - 1) {
                Toast.fail('已到最后一位')
                return
            } else if (this.markIndex < this.mark.teacherStates.length - 1) {
                this.markIndex++
            } else {
                this.markIndex = this.mark.teacherStates.length - 1
            }
            this.teacherId = this.mark.teacherStates[this.markIndex].TeacherId
            this.getInfo()
        },
        changeScore(val, e, i) {
            if (val > e) {
                this.data.TemplateParents[i].ManUser_Score = e
            }
        },
        // changeScoreSelf(val, e, i) {
        //     if (val > e) {
        //         this.data.TemplateParents[i].Self_Assessment_Score = e
        //     }
        // },


        changeChild(val, e, p, i) {
            if (val > e) {
                this.data.TemplateParents[p].Children[i].ManUser_Score = e
                Toast.fail('考核分数不能超过标准分的分值')
            }
            this.refashMaxScore()
        },
        // changeChildSelf(val, e, p, i) {
        //     if (val > e) {
        //         this.data.TemplateParents[p].Children[i].Self_Assessment_Score = e
        //     }
        // },

        submit() {
            if (this.data.AssessmentType == 1 && this.data.IsSelfComplate == 0) {
                Toast.fail('当前教师尚未自评')
                return
            } else {
                this.checkSubmit()
            }
        },
        checkSubmit() {
            let data = {
                IsComplete: 1,
                IsSelfComplate: 1,
                Assessment_Guid: this.data.Assessment_Guid,
                MainId: this.data.Record_Main_Id,
                assRecords: [],
                Man_Remark:this.data.Man_Remark
            }
            this.data.TemplateParents.map(item => {
                if (item.Children.length) {
                    item.Children.map(val => {
                        let obj = {
                            Assessment_Details_Id: val.ID,
                            Self_Assessment_Score: val.Self_Assessment_Score,
                            ManUser_Score: val.ManUser_Score
                        }
                        data.assRecords.push(obj)
                    })
                } else {
                    let obj = {
                        Assessment_Details_Id: item.ID,
                        Self_Assessment_Score: item.Self_Assessment_Score,
                        ManUser_Score: item.ManUser_Score
                    }
                    data.assRecords.push(obj)
                }
            })
            let self = this
            this.whale.remote.getResult({
                url: 'api/Mobile/PFM/PfmRecord/ManCreateOrUpdateScore',
                data: data,
                completed() {
                    if (self.markIndex == self.mark.teacherStates.length - 1) {
                        Toast.fail('已到最后一位')
                        return
                    } else if (self.markIndex < self.mark.teacherStates.length - 1) {
                        self.markIndex++
                    } else {
                        self.markIndex = self.mark.teacherStates.length - 1
                    }
                    self.teacherId = self.mark.teacherStates[self.markIndex].TeacherId
                    self.getInfo()
                }
            })
        },
        refashMaxScore(){
            var self=this;
            self.allDivide=0;
            self.allSelfDivide=0;
            self.data.TemplateParents.forEach((item) => {
                if (item.Is_SetScore) {
                    self.allDivide += Number(item.ManUser_Score);
                    self.allSelfDivide += Number(item.Self_Assessment_Score);
                } else {
                    item.Children.forEach((val) => {
                        self.allDivide += Number(val.ManUser_Score);
                        self.allSelfDivide += Number(val.Self_Assessment_Score);
                    });
                }
            });
        },   
        mfScore(){
            let self = this
            self.data.TemplateParents.forEach((item) => {
                if (item.Is_SetScore) {
                    item.ManUser_Score=item.Parent_Score_Max;
                } else {
                    item.Children.forEach((val) => {
                        val.ManUser_Score=val.Child_Score_Max;
                    });
                }
            });
            this.refashMaxScore()

        },
    },
}
</script>
<style lang="less" scoped>
.page {
    background: #fff;
    height: 100vh;
}
.fontred{
    -webkit-text-fill-color: red !important;
}

.top {
    background: #fff;
    // padding-top: 10px;

    .teacherBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #f4f8fb;
        padding: 10px 5px;

        .name {
            img {
                width: 30px;
                height: 30px;
                vertical-align: middle;
                margin-right: 4px;
            }

            font-size: 15px;
        }

        .yes {
            color: #0195ff;
            border: 1px solid #80caff;
            border-radius: 50px;
            font-size: 12px;
            padding: 4px 10px;
        }

        .not {
            color: #a3b1bc;
            border: 1px solid #d1d8dd;
            border-radius: 50px;
            font-size: 12px;
            padding: 4px 10px;
        }
    }

    .title {
        width: 80%;
        margin: 20px auto;
        color: #0080ee;
        font-weight: bold;
        font-size: 18px;
        text-align: center;
    }

    .tips {
        display: flex;
        justify-content: space-between;
        color: #222222;
        border-bottom: 1px dashed #222222;
        padding-bottom: 5px;
        width: 90%;
        margin: 0 auto;
        font-size: 15px;
        
        .tipstext {
            font-size: 15px;
        }
        

        .tipsbtn {
            font-size: 12px;
            min-width: 80px;
            .tipsbtntext{
                color: #0195ff;
                border: 1px solid #80caff;
                border-radius: 50px;
                padding: 4px 10px;
            }
        }
    }

    .info {
        color: #98a3ad;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        margin: 0 auto;
        font-size: 14px;
        padding: 10px;
    }
}

.center {
    background: #fff;
    padding: 10px 10px 70px;

    .item {
        .bigTit {
            background: #f0f9ff;
            border-left: 3px solid #0195ff;
            font-weight: bold;
            padding: 10px 0 10px 20px;
            color: #0195ff;
            margin-bottom: 10px;
        }

        .bigTopic {
            background: #f6f7fb;
            width: 90%;
            margin: 0 auto;
            padding: 20px;
            box-sizing: border-box;
            margin-bottom: 10px;
        }

        .childBox {
            width: 90%;
            margin: 0 auto;

            .child {
                .smallTit {
                    font-weight: bold;
                    margin-bottom: 10px;
                }

                .smallTopic {
                    background: #f6f7fb;
                    // width: 90%;
                    margin: 0 auto;
                    padding: 20px;
                    box-sizing: border-box;
                    margin-bottom: 10px;
                }
            }
        }
        
        .scoreBig {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: #fafafa;
                padding: 20px;
                width: 90%;
                box-sizing: border-box;
                margin: 0 auto;

                >div {
                    width: 50%;

                    &:nth-child(2) {
                        text-align: right;
                    }
                }

                label {
                    width: 40%;
                    color: #1ea0fa;
                    font-size: 20px;
                    text-align: left;
                }
            }
    }
}

.btn {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .next {
        color: #1492ff;
        margin: 0 20px;
    }

    button {
        font-size: 16px;
        border-radius: 10px;
        width: 40%;
    }
}
</style>